<template>
  <div class="teamtask-list-wrap">
    <el-table v-if="data.length" @selection-change="handleSelectionChange" :data="data" class="teamtask-table" :show-header="isTeamOwner" :row-class-name="handleRowClass" @row-click="handleRowClick">
      <el-table-column v-if="isTeamOwner" type="selection" width="55" />
      <el-table-column min-width="250">
        <template v-slot="scope">
          <div :style="{'paddingLeft':isTeamOwner ? '0' : '26px'}" class="env-time" v-if="scope?.row">
            <div class="env-icon">
              <svg-icon :name="getOSIcon(scope?.row?.os, scope?.row?.os_env, scope?.row?.strategy, scope?.row)"></svg-icon>
            </div>
            <div class="time">
              <div class="env-text">{{scope?.row?.strategy === 1 || (scope?.row?.strategy === 0 && !scope?.row?.real_os_env) ? $t('taskhub.static') : scope?.row?.os_env?.join(' ')}}</div>
              <div class="env-updateTime">{{scope?.row?.added_on}}</div>
            </div>
          </div>
        </template>
        <template #header>
          <div class="teamtask-operate" @click="remove">{{$t('taskhub.team.remove')}}</div>
        </template>
      </el-table-column>
      <el-table-column min-width="150">
        <template v-slot="scope">
          <div class="type_score" v-if="scope?.row">
            <score :score="scope?.row?.score"></score>
            <div class="file-icon">
              <img :src="filetypeIcon(scope?.row)" alt="" />
              <div class="file_type">{{ scope?.row?.file_type || (scope?.row?.category === 'url' ? 'URL' : '') }}</div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column min-width="240">
        <template v-slot="scope">
          <div class="name-type-tag" v-if="scope?.row">
            <div class="filename">{{scope?.row?.target}}</div>
            <!-- <div class="filetype">{{scope.row.file_type}}</div> -->
            <div class="tags"><TagList :data="scope?.row?.tag" :showMore="scope?.row?.filter" /></div>
          </div>
        </template>
      </el-table-column>
      <el-table-column min-width="320" prop="hash">
        <template v-slot="scope">
          <div class="hash" v-if="scope?.row">
            <div v-for="item in ['md5', 'sha1', 'sha256']" :key="item" class="hash-item">
              <span class="label">{{item.toUpperCase()}}：</span>
              <span class="value">
                <copy-icon
                  v-if="scope?.row?.[item]"
                  :name="`${item}${scope?.row?.id}`"
                  :copyText="scope?.row?.[item] || ''"
                  style="vertical-align: middle;"
                  :grey="true"
                />
                {{scope?.row?.[item] || ''}}
              </span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column width="150" v-if="showOwner">
        <template v-slot="scope">
          <div class="submit" v-if="scope?.row">
            <img src="@/assets/img/team/user.svg">
            <div>{{scope?.row?.owner_username || '-'}}</div>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import TagList from '@/components/common/tagList'
import score from '@/components/echarts/score'
import { postDeleteTask } from 'services/team'
import { t } from 'app/i18n'

export default {
  name: 'TeamTaskList',
  components: {
    TagList,
    score
  },
  props: {
    data: {
      type: Array
    },
    showOwner: {
      type: Boolean
    },
    isTeamOwner: {
      type: Boolean
    },
    teamId: {
      type: String
    }
  },
  data () {
    return {
      checked: []
    }
  },
  methods: {
    handleRowClick (data, col, event) {
      // 阻止跳转
      const preventList = [
        // 标签
        'tabList-item',
        // 标签箭头
        'tabList-img',
        // 拷贝
        'copy',
        // 公开私有按钮
        'public-btn',
        // 单选框
        'el-checkbox__inner',
        'el-checkbox__original',
        'list-row__checkbox',
        'svg-icon'
      ]
      if (Array.from(event.target.classList).find(item => preventList.includes(item))) return
      if (data.id) {
        this.$emit('rowClick', data)
      }
    },
    handleSelectionChange(item) {
      this.checked = item.map(v => v.id)
    },
    remove() {
      if (this.checked.length === 0) {
        this.$store.commit('app/showMessage', { type: 'warning', message: t('taskhub.team.warning'), duration: 800 })
        return false
      }
      const team_id = this.teamId
      postDeleteTask({
        team_id,
        task_ids: this.checked.map(item => {
          return item + ''
        })
      }).then((res) => {
        if (res.flag) {
          this.$store.commit('app/showMessage', { type: 'success', message: t('taskhub.team.success'), duration: 800 })
          this.$emit('refresh')
        }
      })
    },
    handleRowClass () {
      return 'teamtask-list-row'
    },
    getOSIcon (os, env, strategy, row) {
      let os2 = os !== 'linux' ? os : env[0].toLowerCase()
      if (strategy === 1 || (strategy === 0 && !row?.real_os_env)) {
        os2 = 'static'
      }
      const keyMap = {
        ubuntu: 'ubuntu',
        linux: 'linux',
        android: 'android',
        windows: 'windows',
        static: 'static-big'
      }
      return keyMap[os2] ? keyMap[os2] : 'windows'
    },
    filetypeIcon(row) {
      if (row?.os === 'android') {
        return require('@/assets/img/svg/filetype/apk.svg')
      }
      const type = row?.file_type
      let file = ''
      if (row?.category === 'file' || type) {
        const list = [
          'html',
          'hwp',
          'js',
          'mhtml',
          'powershell',
          'python',
          'rar',
          'rtf',
          'swf',
          'VBS-icon',
          'email'
        ]
        if (list.includes(type)) {
          file = type
        } else if (type === 'doc' || type === 'docx') {
          file = 'doc'
        } else if (type === 'xls' || type === 'xlsx') {
          file = 'excel'
        } else if (type === 'dll32' || type === 'dll64') {
          file = 'dll'
        } else if (type === 'exe32' || type === 'exe64' || type === 'nsis_exe') {
          file = 'exe'
        } else if (type === 'ppt' || type === 'pptx') {
          file = 'ppt'
        } else if (type === 'bzip2' || type === 'gzip' || type === 'zip') {
          file = 'zip'
        } else {
          file = 'unknow'
        }
      } else if (row?.category === 'url') {
        file = 'IE'
      } else if (row.category === 'md5') {
        file = 'md5'
      }
      return require(`@/assets/img/svg/filetype/${file}.svg`)
    }
  }
}

</script>

<style lang='scss' scoped>
  .teamtask-list-wrap {
    // width: calc(100vw - 282px);
    // height: calc(100vh - 332px);
    // overflow-y: auto;
    .teamtask-table {

      background: inherit;
      :deep(.el-table__header-wrapper) {
        margin-top: 6px;
        .el-table-column--selection {
          padding-left: 17px;
        }
        .el-checkbox__inner {
          width: 14px;
          height: 14px;
        }
        .teamtask-operate {
          color: var(--color-text-2);
          font-weight: 400;
          cursor: pointer;
          width: 70px;
        }
        th {
          border-bottom: none;
        }
      }
      :deep(.el-table__inner-wrapper::before) {
        display: none;
      }
      :deep(.el-table__body-wrapper) {
        .el-table-column--selection {
          padding-left: 20px;
        }
        .el-table__body {
          // 解决table在safari兼容
          width: 100%;
          table-layout: fixed !important;
          border-collapse:separate;
          border-spacing:0px 6px;
        }
        .teamtask-list-row {
          color: var( --color-unkown-text);
          cursor: pointer;
          background-color: var(--color-bg-3);
          // box-shadow: 0 0 10px 0 var(--popover-box-shadow1);
          border-radius: 2px;
          padding: 2px 4px;
          height: 88px;
          .el-checkbox__inner {
            width: 14px;
            height: 14px;
          }
          .el-table-column--selection {
            padding-left: 17px;
          }
          td {
            min-height: 76px;
            padding: 6px 0;
            border: none;
            .env-time {
              // padding-left: 26px;
              display: flex;
              align-items: center;
              .env-icon {
                margin-right: 12px;
                font-size: 36px;
                color: var(--color-text-3);
              }
              .time {
                .env-text {
                  width: 100%;
                  line-height: 21px;
                  margin-bottom: 2px;
                  font-size: 14px;
                  color: var(--color-text-2);
                  font-weight: bold;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
                .env-updateTime {
                  color: var(--color-text-2);
                  opacity: 0.7;
                }
              }
            }
            .type_score {
              min-width: 150px;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 0 10px;
              .score {
                min-width: 70px;
                height: 70px;
              }

              .file-icon {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-left: 20px;
                min-width: 60px;
                img {
                  display: inline-block;
                  width: 26px;
                  height: 26px;
                  vertical-align: middle;
                  padding-bottom: 8px;
                  filter: var(--svg-filter1);
                }
                .file_type {
                  color: var(--color-text-2);
                  font-size: 14px;
                  line-height: 14px;
                  font-weight: 500;
                }
              }
            }
            .type-score {
              text-align: center;
              .filetype-icon {
                margin-bottom: 10px;
                height: 24px;
                img {
                  height: 24px;
                  width: 20px;
                }
              }
              .score {
                display: inline-block;
                color: var(--color-text-1);
                border-radius: 4px;
                font-size: 14px;
                width: 60px;
                height: 22px;
                line-height: 22px;
                font-family: Verdana;
                font-weight: 600;
                background: #ccc;
                &.green {
                  background: #3EAE89;
                }
                &.orange {
                  background: #E69F34;
                }
                &.red {
                  background: #CF605B;
                }
              }
            }
            .name-type-tag {
              font-size: 14px;
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: flex-start;
              text-align: left;
              padding: 20px 20px 13px;
              box-sizing: border-box;
              min-height: 85px;
              .filename {
                font-size: 14px;
                line-height: 18px;
                font-weight: bold;
                margin-bottom: 4px;
                color: var(--color-text-1);
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              .filetype {
                font-size: 14px;
                line-height: 14px;
                height: 14px;
                margin-bottom: 5px;
                // ft_color(color-table-body-filetype);
                font-weight: 500;
              }
              .tags {
                // min-height: 26px;
                width: 100%;
                margin-top: 6px;
              }
            }
            .hash {
              .hash-item {
                line-height: 20px;
                font-size: 14px;
                width: 100%;
                height: 20px;
                span {
                  vertical-align: middle;
                }
                .label {
                  display: inline-block;
                  width: 70px;
                  color: var(--color-text-2);
                }
                .value {
                  display: inline-block;
                  width: calc(100% - 90px);
                  color: var(--color-text-2);
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
              }
            }
            .submit {
              display: flex;
              color: var(--color-text-2);
              img {
                margin-right: 3px;
              }
              div {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
              }
            }
          }
          // &:hover {
          //   td {
          //     background: #3c4875;
          //   }
          // }
        }
      }
      &::before {
        height: 0;
      }
    }
  }
</style>
