<template>
  <div class="teamtask-wrap">
    <template v-if="!checkLoading && !showErrPage">
      <div class="teamtask-container" v-if="teamList.length > 0">
        <div class="teamtask-teaminfo">
          <div class="teamtask-title">{{$t('taskhub.team.title')}}</div>
          <div class="teamtask-left">
            <div class="teaminfo-teamlist">
              <el-tooltip :content="team.team_name" :effect="$isDark() ? 'dark' : 'light'" placement="right" :disabled="team.team_name.length < 9" v-for="team in teamList" :key="team.team_id" >
                <div @click="handleChangeTeam(team)" :class="['teaminfo-team-item', {active: team.team_id === currentTeamInfo.team_id}]">{{team.team_name}}</div>
              </el-tooltip>
            </div>
            <!-- <el-button @click="$router.push('/usercenter/ownerteam')">团队管理</el-button> -->
            <div class="team-manage">
              <el-button @click="$router.push('/usercenter/ownerteam')">{{$t('taskhub.team.manage')}}</el-button>
            </div>
          </div>
        </div>
        <div class="rightWrapper" v-if="checked">
          <div>
            <div class="teamtask-list">
              <div class="teamtask-name">
                <span>
                  <img src="@/assets/img/team/team-name.svg" />
                </span>
                <span>
                  {{currentTeamInfo.team_name}}
                </span>
                <span class="teamtask-role">{{isTeamOwner ? $t('taskhub.team.admin') : $t('taskhub.team.member')}}</span>
              </div>
              <div class="teamtask-filter">
                <switch-tab
                  class="teamtask-type"
                  :list="switchList"
                  :val="tasktype"
                  @change="handleChangeTaskType"
                />
                <div class="teamtask-options">
                  <filter-option v-if="hasDom" :key="currentTeamInfo.team_id" pageType="team_filter" :extraFilters="extraFilters" :teamId="currentTeamInfo.team_id" :teamTaskSubmitter="teamTaskSubmitter" :stayCollapse="stayCollapse"></filter-option>
                </div>
              </div>
              <div id="filterWrapper"></div>
              <Loading :loading="loading" v-if="loading" />
              <template v-if="!loading">
                <TeamTaskList v-if="teamTaskList.length > 0 && done" :isTeamOwner="isTeamOwner" :showOwner="showTaskOwner" :teamId="currentTeamInfo.team_id" :data="teamTaskList" @refresh="getTeamShareTask" @rowClick="handleRowClick" />
                <div class="no-teamtask" v-if="!teamTaskList.length">
                  <img :src="$isDark() ? require('@/assets/img/no-data-list.png') : require('@/assets/img/no-data-list-light.png')" alt="">
                  <div class="no-teamtask-tip">{{$t('taskhub.team.nodata')}}</div>
                </div>
              </template>
              <div class="pagination-wrap" v-if="!loading">
                <Pagination
                  @handleSizeChange="handleSizeChange"
                  @handleCurrentChange="handleCurrentChange"
                  :totalSize="totalSize"
                  :current_page="currentPage"
                  :page-size="pageSize"
                  customTotal
                />
              </div>
            </div>
            <detail-footer/>
          </div>
        </div>
      </div>
      <div class="no-team" v-if="!teamList.length && !team_loading">
        <div class="no-team__wrap">
          <no-data style="marginTop:210px;" :data="$t('taskhub.team.noteam')"></no-data>
        </div>
        <detail-footer/>
      </div>
    </template>
    <Loading :loading="checkLoading" v-if="checkLoading" />
    <div class="close-captch__wrap" v-if="showErrPage">
      <close-captch :showFooter="false"></close-captch>
    </div>
  </div>
  <backTo-top isContainer=".rightWrapper"></backTo-top>

</template>

<script>
import TeamTaskList from './components/TeamTaskList'
import SwitchTab from '../component/SwitchTab.vue'
import Pagination from '@/components/common/Pagination'
import closeCaptch from '@/components/common/closeCaptch.vue'
import filterOption from '../component/filter'
import { getTeamTask } from 'services/team'
import { checkLogin } from 'services/auth'
import emitter from '@/app/emitter.js'
import { t } from 'app/i18n'
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'Teamtask',
  components: {
    TeamTaskList,
    SwitchTab,
    Pagination,
    filterOption,
    closeCaptch
  },
  props: {},
  data () {
    return {
      switchList: [
        {
          label: t('taskhub.team.all'),
          value: ''
        },
        {
          label: t('taskhub.team.file'),
          value: 'file'
        },
        {
          label: 'URL',
          value: 'url'
        }
      ],
      drawer: false,
      currentTeamId: this.$route.query.teamId || '',
      loading: true,
      hasDom: false,
      totalSize: 0,
      pageSize: 50,
      currentPage: Number(this.$route.query.page) || 1,
      teamTaskList: [],
      filter: {},
      checked: false,
      done: false,
      stayCollapse: false,
      team_loading: true,
      checkLoading: true,
      showErrPage: false
    }
  },
  computed: {
    ...mapGetters({
      tasktype: 'teamtask/tasktype',
      page: 'teamtask/page',
      size: 'teamtask/size',
      total: 'teamtask/total',
      teamTaskSubmitter: 'teamtask/teamTaskSubmitter',
      isFilter: 'teamtask/isFilter',
      teamList: 'team/teamList',
      getUserInfo: 'getUserInfo'
    }),
    currentTeamInfo () {
      if (this.currentTeamId) {
        return this.teamList.find((item) => item.team_id === parseInt(this.currentTeamId)) || { team_name: '' }
      } else {
        return this.teamList.length > 0 ? this.teamList[0] : {}
      }
    },
    showTaskOwner () {
      return this.currentTeamInfo.team_owner === this.getUserInfo.username || this.currentTeamInfo.is_show_members === 1
    },
    isTeamOwner () {
      return this.currentTeamInfo.team_owner === this.getUserInfo.username
    }
  },
  watch: {
    'currentTeamInfo.team_id'(v) {
      this.setTaskId(v)
    },
    '$route.query'(n, o) {
      if (n !== o) {
        this.$nextTick(() => {
          this.checked = true
          this.hasDom = true
        })
      }
    }
  },
  methods: {
    ...mapMutations({
      setTaskId: 'teamtask/SET_TEAMID'
    }),
    handleChangeTaskType (val) {
      this.$store.commit('teamtask/SET_TASK_TYPE', val)
      this.tasktype = val
      this.currentPage = 1
      this.checked = false
      this.loading = true
      this.$nextTick(() => {
        this.checked = true
      })
    },
    handleRowClick (row) {
      const router = { name: 'report', params: { taskId: row.id } }
      const { href } = this.$router.resolve(router)
      window.open(href, '_blank')
    },
    handleChangeTeam (team) {
      if (team.team_id === Number(this.$route.query.teamId)) return
      this.checked = false
      this.loading = true
      this.hasDom = false
      this.currentTeamId = team.team_id
      this.stayCollapse = false
      // this.handleChangeTaskType('')
      // this.$nextTick(() => {
      //   this.hasDom = true
      // })
      this.$router.replace({ name: 'teamtask', query: { teamId: team.team_id } })
      this.$forceUpdate()
    },
    handleSubmit (refresh) {
      if (refresh) {
        this.handleCurrentChange(1)
      }
    },
    handleSizeChange (size) {
      this.pageSize = size
      this.currentPage = 1
      this.checked = false
      this.loading = true
      this.$nextTick(() => {
        this.checked = true
      })
    },
    handleCurrentChange (page) {
      this.currentPage = page
      this.$router.replace({ path: this.$route.path, query: { ...this.$route.query, page } })
      this.getTeamShareTask()
    },
    async getTeamShareTask () {
      this.loading = true
      this.done = false
      this.teamTaskList = []
      await getTeamTask({
        page: this.currentPage,
        size: this.pageSize,
        category: this.tasktype,
        team_id: this.currentTeamInfo.team_id,
        ...this.filter
      })
        .then(res => {
          this.teamTaskList = res.task
          this.loading = false
          this.done = true
          this.checked = true
          this.totalSize = res.total
        })
        .catch(err => {
          console.log(err)
          if (err.message === 'Request failed with status code 401') {
            this.showErrPage = true
          }
        })
    },
    getLoginStatus() {
      checkLogin().then(async (res) => {
        this.checkLoading = false
        if (!res.is_login) {
          this.showErrPage = true
        } else {
          this.showErrPage = false
          await this.$store.dispatch('team/getTeamMembers')
          // // this.getTeamShareTask()
          this.$nextTick(() => {
            this.team_loading = false
            this.hasDom = true
          })
          this.checked = true
          // this.getTeamShareTask()
        }
      })
    }
  },
  mounted () {
    emitter.on('team_filter/filterChange', (v, bol) => {
      if (bol && JSON.stringify(this.filter) !== JSON.stringify(v)) {
        this.checked = false
        this.loading = true
        this.stayCollapse = true
        return
      }
      this.filter = v
      this.getTeamShareTask()
    })
    this.getLoginStatus()
    emitter.on('reloadResult', async () => {
      this.showErrPage = false
      await this.$store.dispatch('team/getTeamMembers')
      this.$nextTick(() => {
        this.team_loading = false
        this.hasDom = true
      })
      this.checked = true
      this.getTeamShareTask()
    })
  },
  // created () {
  //   this.$store.dispatch('team/getTeamMembers').then(res => {
  //     // this.getTeamShareTask()
  //     this.$nextTick(() => {
  //       this.team_loading = false
  //       this.hasDom = true
  //     })
  //   })
  //   this.checked = true
  // },
  unmounted () {
    emitter.off('team_filter/filterChange')
    emitter.off('reloadResult')
    this.$store.commit('teamtask/SET_TASK_TYPE', '')
  }
}
</script>

<style lang='scss' scoped>
  [data-theme='light'] .teamtask-container {
    background-color: white;
  }
  .teamtask-wrap {
    height: 100%;
    .teamtask-container {
      display: flex;
      .teamtask-teaminfo {
        width: 180px;
        flex-shrink: 0;
        min-height: calc(100vh - 116px);
        .teamtask-title {
          font-size: 18px;
          padding-left: 24px;
          line-height: 50px;
          height: 50px;
          font-size: 18px;
          color: var(--color-text-1);
          width: 100%;
          box-sizing: border-box;
          font-weight: 500;
        }
        .teamtask-left {
          height: calc(100vh - 106px);
          position: relative;
          &::after {
            content: '';
            position: absolute;
            right: 1px;
            top: 0;
            height: 100%;
            width: 1px;
            border-left: 2px solid var(--color-border-2);
          }
          .team-manage {
            // margin: 10px 12px 0 36px;
            padding: 15px 0 0;
            margin: 0px 12px 0 36px;
            border-top: 1px solid var(--color-border-2);
            display: flex;
            justify-content: center;
            box-shadow: 0 -10px 8px -10px var(--popover-box-shadow1);
            span {
              width: 88px;
              text-align: center;
              margin: 12px 0;
              border: 1px solid var(--color-text-3);
              line-height: 32px;
              border-radius: 2px;
              height: 32px;
              font-size: 14px;
              color: var(--color-text-2);
              cursor: pointer;
              &:hover {
                color: var(--color-priamry);
                border-color: var(--color-priamry);
              }
            }
          }
        }
        .teaminfo-teamlist {
          padding-left: 24px;
          height: calc(100vh - 175px);
          &::-webkit-scrollbar{width:0px;}
          overflow-y: auto;
          .teaminfo-team-item {
            height: 40px;
            line-height: 40px;
            border-radius: 2px 0px 0px 2px;
            cursor: pointer;
            // margin: 10px 0;
            font-size: 14px;
            width: calc(100% - 2px);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: var(--color-text-2);
            padding-right: 16px;
            padding-left: 8px;
            text-align: right;
            box-sizing: border-box;
            border-right: 2px solid transparent;
            &:hover {
              background-color: var(--color-bg-3);
            }
            &.active {
              color: var(--color-text-1);
              border-right: 2px solid #00ab7a;
              // background: var(--detect-config-bg);
              background-color: var(--color-bg-3);
              position: relative;
              z-index: 1;
              font-weight: 500;
            }
          }
        }
      }
      .rightWrapper {
        width: calc(100% - 180px);
        overflow-x: auto;
        overflow-x: overlay;
        &>div {
          min-width: 1210px;
          height: calc(100vh - 56px);
          // overflow-y: auto;
          overflow-y: overlay;
          display: flex;
          overflow-x: hidden;
          flex-direction: column;
          justify-content: space-between;
        }
      }
      .teamtask-list {
        min-width: 1086px;
        display: flex;
        box-sizing: border-box;
        flex-direction: column;
        padding: 58px 20px 0px 20px;
        .teamtask-name {
          font-size: 16px;
          line-height: 20px;
          color: var( --color-unkown-text);
          margin-bottom: 16px;
          position: relative;
          span {
            vertical-align: middle;
            color: var(--color-text-1);
            font-weight: 500;
            img {
              vertical-align: middle;
              margin-right: 8px;
            }
          }
          .teamtask-role {
            border-left: 1px solid var(--color-text-1);
            margin-left: 10px;
            padding-left: 10px;
            font-weight: normal;
          }
          .teamtask-list-option {
            position: absolute;
            top: -8px;
            right: 4px;
            button {
              background-color: transparent;
              color: var(--color-priamry);
              border-color: var(--color-priamry);
              svg {
                transition: all 0.5s;
              }
              i {
                & .active {
                  transform: rotateZ(180deg);
                }
              }
            }
          }
        }
        .teamtask-filter {
          // margin-bottom: 6px;
          display: flex;
          justify-content: space-between;
          .teamtask-type {
          }
          .teamtask-options {
            :deep(.public-list-option) {
              top: 1px;
            }
          }
          .filter-icon {
            cursor: pointer;
            img {
              width: 32px;
              height: 32px;
            }
          }
        }
        .teamtask-operate {
          display: flex;
          height: 35px;
          background: var(--detect-config-bg);
          box-shadow: 0 0 10px 0 var(--popover-box-shadow1);
          border-radius: 2px;
          margin-top: 8px;
        }
        .no-teamtask {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: calc(100vh - 293px);
          // min-height: 768px;
          img {
            width: 112px;
            height: 112px;
          }
          .no-teamtask-tip {
            margin-top: 10px;
            color: var( --color-unkown-text);
            font-size: 14px;
          }
        }
        .pagination-wrap {
          margin: 16px -21px 24px 0;
          text-align: right;
        }
      }
    }
    .no-team {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-height: 768px;
      height: calc(100vh - 56px);
      justify-content: space-between;
      :deep(.home-footer) {
        width: 100%;
      }
      .no-team__wrap {
        height: calc(100% - 60px);
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      img {
        width: 112px;
        height: 112px;
      }
      .no-teamtask-tip {
        margin-top: 10px;
        color: var( --color-unkown-text);
        font-size: 14px;
      }
    }
    .close-captch__wrap {
      height: calc(100vh - 56px - 60px);
    }
  }
</style>
